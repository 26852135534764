<template>
  <section>
    <div class="row mb-3 p-0">
      <div class="col-12 card border-0 p-3">
          <div class="row">
            <div class="col-12">
              <h6 class="text-center m-0">กรุณาเลือก SaleSource</h6>
            </div>
            <div class="col-12">
              <hr class="m-1">
            </div>
          </div>
          <div class="row mt-2">
            
            <div v-for="(e,index) in saleSourceData" :key="index" class="col-6 col-md-3">
              <button 
                v-if="e.MAINSALESOURCE === 'UL' && salesourceDisabled.includes(e.SUBSALESOURCE) && $store.getters['auth/getQTUSERID'] != 'BOODEV'" 
                :style="{backgroundColor:'#aaa'}" 
                disabled
                class="btn ">
                {{e.salesourcePrefix}}
              </button>
              <button v-else @click="btnSelectSaleSource(e.SUBSALESOURCE, e.QTETAX)" :style="{backgroundColor:e.salesourceColor}" class="btn ">{{e.salesourcePrefix}}</button>
            </div>
            
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import Modal from '@/components/Modal.vue'
export default {
  name: "SelectSaleSource",
  data() {
    return {
      SalePersonData:'',
      saleSourceData:[],
      salesourceDisabled: []
    };
  },
  components: {
    Modal
  },
  mounted() {
    this.getSaleSourceData()
  },
  methods: {
    async getSaleSourceData(){
      try {
        let data={
          SALESOURCE:this.getQTSALESOURCE
        }
        let getAPI = await serviceAPI.call_API('post',`user/getSaleSourceData`,data,'auth');
        this.saleSourceData = getAPI.data.dbitems
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async btnSelectSaleSource(para_salesource, para_qtetax){  
      this.$store.dispatch('auth/setQTSUBSALESOURCE', para_salesource)
      this.QTETAX = para_qtetax
      if(this.getRef){
        this.updateSaleSource()
      }
    },
    async updateSaleSource(){
      try {

        if(!this.getRef){
          throw new Error("กรุณาเลือกตะกร้าก่อนครับ")
        }
        let data={
          REF: this.getRef, 
          SALESOURCE:this.getQTSUBSALESOURCE
        }
        let getAPI = await serviceAPI.call_API('post',`user/updateSalesource`,data,'auth');
         
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    
  
  },
  computed: {
    getRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    getQTSUBSALESOURCE: function () {
      return this.$store.getters['auth/getQTSUBSALESOURCE']
    },
    getQTSALESOURCE: function () {
      return this.$store.getters['auth/getQTSALESOURCE']
    },
    QTETAX: {
      get() {
        return this.$store.getters["auth/getQTETAX"];
      },
      set(value) {
        this.$store.dispatch("auth/setQTETAX", value);
      },
    }
    
  },
};
</script>

<style scoped lang="scss">
.btn-line{
  background-color:#00c300;
}
.btn-facebook{
  background-color:#1877f2;
}
.btn-callshop{
  background-color:	#FFA500;
}
.btn{
  color:white;
  font-weight: bold;
  padding:0px 10px;
  width: 100%;
  margin-bottom:10px;
}
</style>
