<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 col-md-6">
          <FindPhoneForm ref="FindPhoneFormRef"/>
      </div>
      <div class="col-12 col-md-6 " style="border-left:1px black solid; ">
          <Registerform ref="RegisterFormRef" @confirmCreateUser="confirmCreateUser" />
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
          <UserOrderHistory ref="UserOrderHistoryRef"  />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Registerform from '@/components/FindUser/Registerform.vue'
import FindPhoneForm from '@/components/FindUser/FindPhoneForm.vue'
import UserOrderHistory from '@/components/FindUser/UserOrderHistory.vue'

export default {
  name: 'FindUser',
  components: {
    Registerform,
    FindPhoneForm,
    UserOrderHistory,
  },
  props: {

  },
    data: function () {
    return {
   
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - FindUser`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
    this.isFromCheckoutConfirm()
  },
  methods: {
    async findMailByPhone(){
          // this.$root.$refs.RegisterFormRef.randomUser();
          this.$refs.RegisterFormRef.randomUser()
        },
    confirmCreateUser(para_CreateUser){
      this.$refs.FindPhoneFormRef.dataPhone=para_CreateUser.MOBILE;
      this.$refs.FindPhoneFormRef.findUser();
    },
    isFromCheckoutConfirm(){
      if(this.$route.query.confirm =='success'){
        this.$store.dispatch('customer/clearAfterConfirmCheckout')
        this.$store.dispatch('service/clearAfterConfirmCheckout')
      }
    }

  },
}
</script>
