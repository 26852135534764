<template>
<section>
    <div class="row login-form" style="padding: 0px 40px ; ">
        <!--   NEW MEMBER  -->

        <div id="form-regis" class="col-md-12 col-regis">
            <h2 style="margin-top: 5px;">ค้นหาลูกค้า </h2>
            <div class="row" id='sel-salesource' v-if='salesourceCondition.includes(getQTSALESOURCE)'>
                <div class="col-12 mt-3">
                    <SelectSaleSource/>
                </div>
            </div>
            <div class="row" v-if="!salesourceCondition.includes(getQTSALESOURCE) || salesourceCondition.includes(getQTSALESOURCE) && getQTSALESOURCE != getQTSUBSALESOURCE">
                <div class="col-12" style="margin: 0px;
                            background-color: white;
                            padding:20px 20px;
                            border-radius: 7px;
                            height: auto;
                            min-height: auto;">
                    <div class="row">
                        <div class="form-group col-12" style="padding: 0px;">
                            <div class="col-12">
                                <label for="MOBILE">กรุณาระบุเบอร์โทรศัพท์</label> <span style="color: red;">*</span>
                            </div>
                            <div class="col-12">
                                <input v-model="dataPhone" type="text" class="" style="width: 100% !important;
                                border-radius: 4px;
                                border: .5px solid #ccc;
                                display: inline-block !important; " ref="MOBILE" name="MOBILE" placeholder="" maxlength="10">
                            </div>
                        </div>

                        <div class="form-group col-12">
                            <button type="button" :class="`twdorbnb btn-${getQTBU}`" style=" " @click="findUser"> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ค้นหาข้อมูล</strong></button>
                        </div>

                    </div>
                </div>
            </div>
            
            <div class="row my-3" v-if="emailForm">
                <div class="col-12 twdorbnb cardWhite" v-if="loadT1C">
                    <form>
                        <div class="form-group p-0 mt-2">
                            <label for="seluser" style="font-family: 'kittithadabold75regular'; color:black; ">E-mail ของลูกค้าคือ</label>
                            <select v-model="dataEmail" name="seluser" @change="seluserchang" class="form-control selectMail">
                                <option v-for="(e,index) in listUser" :key="index" :value="e.EMAIL">
                                    {{ e.EMAIL }}
                                </option>
                            </select>
                        </div>
                        <a :class="`col-12 twdorbnb btn-${getQTBU}`" @click="confirmSelectedUser()"> <strong style="font-family: 'kittithadamedium65regular';">เลือก</strong></a>
                        <button type="button" :class="`mt-1 twdorbnb btn-visible-black`" style=" " @click="clearStore"> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ยกเลิก</strong></button>

                    </form>
                </div>
                <div class="col-12 twdorbnb cardWhite" v-else>
                    <form>
                        <div class="form-group p-0 mt-2">
                            <label for="seluser" style="font-family: 'kittithadabold75regular'; color:black; ">E-mail ของลูกค้าคือ</label>
                            <p><span class="skeleton-box w-100" style="height: 30px; border-radius:50px;"></span></p>
                        </div>
                        <a :class="`col-12 twdorbnb btn-${getQTBU}`" :style="{backgroundColor:'#aaa'}"  > <strong style="font-family: 'kittithadamedium65regular';">เลือก</strong></a>
                        <button type="button" :class="`mt-1 twdorbnb btn-visible-black`" style=" "> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ยกเลิก</strong></button>

                    </form>
                </div>
            </div>

            <br>

            <EditUserInfoForm v-if="userInfoForm" :dataEditProps="dataSelected" @confirmEdit="confirmEdit" />
            <SelectStore />
        </div>
    </div>
    <div v-show="isLoading" class="loading"></div>
</section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import SelectStore from '@/components/SelectStore.vue'
import EditUserInfoForm from '@/components/EditUserInfoForm.vue'
import SelectSaleSource from '@/components/FindUser/SelectSaleSource.vue'
export default {
    name: 'FindPhoneForm',
    data: function () {
        return {
            dataPhone: '',
            listUser: [],
            dataEmail: '',
            emailForm: false,
            userInfoForm: false,
            editForm: false,
            dataSelected: {
                CNAME: '',
                MOBILE: '',
                EMAIL: '',
                T1C: '',
                VIPID: '',
                SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                EMPLOYEEBUSHORTCODE: '',
                EMPLOYEEID: '',
                ISSTAFF: '',
            },
            salesourceCondition: ['UL', 'UD', 'UC'],
            loadT1C: true,
            isLoading:false,
        }
    },
    components: {
        EditUserInfoForm,
        SelectStore,
        SelectSaleSource
    },
    props: {

    },
    mounted() {
        //test
        if(this.$store.getters['auth/getISTB']){
            this.getRefB2B()
        }
    },
    methods: {
        async findUser() {
            try {

                if (this.dataPhone == '') {
                    this.$refs.MOBILE.focus()
                    throw new Error('กรุณากรอกหมายเลขโทรศัพท์');
                }
                this.isLoading = true
                this.listEmail = []
                let getAPI = await serviceAPI.call_API('get', `user/Custinfo/${this.dataPhone}`, {}, 'auth');
                this.listUser = getAPI.data.dbitems;
                this.dataEmail = this.listUser[0] ? this.listUser[0].EMAIL : null

                if(!this.dataEmail) {
                    this.isLoading = false
                    throw new Error('ไม่พบลูกค้าในระบบ กรุณาสมัครสมาชิกก่อนเข้าใช้งาน');
                }
                this.loadT1C = false
                this.emailForm = true;
                this.seluserchang()
                

            } catch (error) {
                this.isLoading = false
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async seluserchang() {
            try {
                let getEmail = this.dataEmail;
                this.userInfoForm = true;
                for (let e of this.listUser) {
                    if (e.EMAIL == getEmail) {
                        this.dataSelected.CNAME = e.CNAME;
                        this.dataSelected.MOBILE = e.MOBILE;
                        this.dataSelected.EMAIL = e.EMAIL;
                        this.dataSelected.T1C = e.T1C;
                        this.dataSelected.VIPID = e.VIPID;

                        break
                    }
                }
                this.getTheOneNumber()

            } catch (error) {
                this.isLoading = false
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async getTheOneNumber(){
            try {
                // if(this.dataSelected.MOBILE.length != 10){
                //     return
                // }
                let data = this.dataSelected;
                let getAPI = await serviceAPI.call_API('post', 'user/findTheOne', data,'auth');
                let getData = getAPI.data.dbitems[0];
                this.loadT1C = true
                if(!getData ||getData.T1CNumber=='NOOK'){
                    // throw new Error('ไม่พบเลขเดอะวัน')
                    // this.dataSelected.T1C = getData.T1CNumber || '' ;
                    this.dataSelected.EMPLOYEEBUSHORTCODE = '';
                    this.dataSelected.EMPLOYEEID = '';
                    this.dataSelected.ISSTAFF = '';
                    this.isLoading = false
                    return
                }
                // this.dataSelected.T1C = getData.T1CNumber || '' ;
                // this.dataSelected.CNAME = `${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}`;
                this.dataSelected.EMPLOYEEBUSHORTCODE = getData.EmployeeBUShortCode || '';
                this.dataSelected.EMPLOYEEID = getData.EmployeeId || '';
                this.dataSelected.ISSTAFF = getData.EmployeeFlag || '';
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
                this.loadT1C = true
                this.dataSelected.EMPLOYEEBUSHORTCODE = '';
                this.dataSelected.EMPLOYEEID = '';
                this.dataSelected.ISSTAFF = '';
                // serviceMain.showErrorAlert(this, error.message)
            }
        },
        async confirmEdit(para_valEdit) {
            try {
                this.dataSelected = para_valEdit;
                this.$store.dispatch('customer/setQTCUSTINFO',para_valEdit )
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async confirmSelectedUser() {
            try {
                if (this.salesourceCondition.includes(this.getQTSUBSALESOURCE)) {
                    document.getElementById('sel-salesource').scrollIntoView()
                    throw new Error("กรุณาเลือก SaleSource ก่อนครับ")
                }

                if (this.dataSelected.EMAIL == '') {
                    throw new Error("กรุณาเลือก Email ก่อนครับ")
                }
                this.isLoading = true
                let data = {
                    "MCID": this.$store.getters['customer/getQTMCID'],
                    "EMAIL": this.dataSelected.EMAIL,
                    "MOBILE": this.dataSelected.MOBILE,
                    "USERID": this.$store.getters['auth/getQTUSERID'],
                    "SALESOURCE": this.getQTSUBSALESOURCE
                }
                // console.log(data)
                let getAPI = await serviceAPI.call_API('post', 'user/getCustref', data, 'auth');
                
                let getData = getAPI.data.dbitems[0];
                
                
                let getCUSTREF = getData.CUSTREF;
                // console.log(getCUSTREF)
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                this.$store.dispatch('customer/setQTCUSTINFO', this.dataSelected)
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTCOPYREF', "")
                this.$store.dispatch('customer/setQTCREDITBAL', "")
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)

                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    this.$store.dispatch('service/setStoreOnline')
                    // if(!this.qtDfStore) {
                    //     $('#mapModal').modal('show')
                    // }
                }
                
                let getSaleSource = getData.SALESOURCE;
                if(this.salesourceCondition.includes(getSaleSource)){
                    this.$store.dispatch('auth/setQTSUBSALESOURCE', this.getQTSUBSALESOURCE)
                }else{
                    this.$store.dispatch('auth/setQTSUBSALESOURCE', getSaleSource)
                }
                    
                this.updateSaleSource()
            } catch (error) {
                this.isLoading = false
                serviceMain.showErrorAlert(this, error.message)
            }

        },
        async updateSaleSource(){
            try {

                if(!this.getRef){
                    this.isLoading = false
                    throw new Error("กรุณาเลือกตะกร้าก่อนครับ")
                }
                let data={
                REF: this.getRef, 
                SALESOURCE:this.getQTSUBSALESOURCE
                }
                let getAPI = await serviceAPI.call_API('post',`user/updateSalesource`,data,'auth');
                this.isLoading = false
                
            } catch (error) {
                this.isLoading = false
                serviceMain.showErrorAlert(this,error.message)
            }
        },
        clearStore(){
            this.$store.dispatch('service/clearStore')
            this.$store.dispatch('customer/clearCustomer')
        },
        async getRefB2B() {
            try {
                let data = {
                    "MCID": this.$store.getters['customer/getQTMCID'],
                    "USERID": this.$store.getters['auth/getQTUSERID']
                }
                // console.log(data)
                let getAPI = await serviceAPI.call_API('post', 'user/getCustrefB2B', data, 'auth');
                
                let getData = getAPI.data.dbitems[0];
                let getAPIcusInfo = await serviceAPI.call_API('post', `user/getCustinfoB2B`, {"USERID": this.$store.getters['auth/getQTUSERID']}, 'auth');
                console.log(getAPIcusInfo);
                let datainfo = getAPIcusInfo.data.dbitems[0]
                let getCUSTREF = getData.CUSTREF;
                // console.log(getCUSTREF)
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                this.$store.dispatch('customer/setQTCUSTINFO', {
                    CNAME: datainfo.CNAME,
                    MOBILE: datainfo.MOBILE,
                    EMAIL: datainfo.EMAIL,
                    T1C: datainfo.T1C,
                    VIPID: datainfo.VIPID,
                    SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                    EMPLOYEEBUSHORTCODE: '',
                    EMPLOYEEID: '',
                    ISSTAFF: '',
                })
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)

                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    this.$store.dispatch('service/setStoreOnline')
                    // if(!this.qtDfStore) {
                    //     $('#mapModal').modal('show')
                    // }
                }
                this.$router.push('/');
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }

        },
 
    },
    computed: {
        getRef:function(){
            return this.$store.getters['customer/getQTCUSTREF']
        },
        getQTBU: function () {
            return this.$store.getters['customer/getQTBU'];
        },
        qtDfStore: function () {
            return this.$store.getters['service/getQTDFSTORE'];
        },
        getQTSALESOURCE: function () {
            return this.$store.getters['auth/getQTSALESOURCE']
        },
        getQTSUBSALESOURCE: function () {
            return this.$store.getters['auth/getQTSUBSALESOURCE']
        },
        getQTETAX: function () {
            return this.$store.getters['auth/getQTETAX']
        }
    }

}
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url("/img/loadsmall.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.cardWhite {
    margin: 0px;
    background-color: white;
    padding: 20px 20px;
    border-radius: 7px;
    height: auto;
    margin-bottom: 0px;
    min-height: auto;
}

.selectMail {
    border-radius: 15px !important;
    height: 35px;
    padding: 0px 15px;
    font-family: 'kittithadabold75regular';
    color: black;
    background-color: rgb(241, 241, 241);
    border-radius: 25px;
}

.form-control {
    height: 30px;
    padding: 0px 15px;
    width: 100% !important;
    border-radius: 4px;
    border: .5px solid #ccc;
    display: inline-block !important;
}

.col-regis {
    padding: 0px 20px !important;
}

.checkmark {
    top: 4px !important;
    height: 20px !important;
    width: 20px !important;
}

.checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.shadowDiv {
    margin: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    height: auto;
}
.shadowDiv:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
