<template>
    <div class="row" >
        <div class="col-12 cardWhite" >
            <div class="row" v-if="!editForm">
                
                <div class="col-6 text-left" style="font-weight: bold;"><i class="fa fa-id-card-o mr-1" style="transform: translateY(10%);"></i> ข้อมูลส่วนตัว</div>
                <div class="col-6 text-right">
                    <a v-show="mode != 'S'" :class="`btn-sm-${qtBu}`" @click="startEdit">แก้ไข</a>
                </div>
                <div class="col-12 ">
                  <hr style="padding:10px 0 0 0; margin: 0px;">
                </div>
                
                <div class="row" style="padding:0px 20px 10px 20px;">
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">ชื่อผู้ใช้</h4>
                        <div id="Sinfocname" style="font-weight: bold;margin: 0px;">{{dataSelected.CNAME}}</div>
                    </div>
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">เบอร์โทรศัพท์มือถือ</h4>
                        <div id="Sinfophone" style="font-weight: bold;margin: 0px;">{{dataSelected.MOBILE}}</div>
                    </div><br><br>
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">อีเมล</h4>
                        <div id="Sinfoemail" style="font-weight: bold;margin: 0px;">{{dataSelected.EMAIL}}</div>
                    </div>
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">หมายเลขสมาชิก The 1</h4>
                        <div id="Sinfotheone" style="font-weight: bold;margin: 0px;">{{dataSelected.T1C}}</div>
                    </div>
                </div>

            </div>
            <div class="row" v-else >
                <div class="col-6 text-left" style="font-weight: bold;"><i class="fa fa-id-card-o mr-1" style="transform: translateY(10%);"></i> แก้ไขข้อมูลส่วนตัว</div>
                <div class="col-6 text-right">
                    <a :class="`btn-sm-${qtBu}`" @click="confirmEdit">ยืนยัน</a>
                </div>
                <div class="col-12 ">
                  <hr style="padding:10px 0 0 0; margin: 0px;">
                </div>
                
                <div class="row" style="padding:0px 20px 10px 20px;">
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">ชื่อผู้ใช้</h4>
                        <input v-model="dataEdit.CNAME" type="text" class="form-control" name="CNAMEE" ref="CNAMEE" maxlength="150" placeholder="Name" >
                    </div>
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">เบอร์โทรศัพท์มือถือ</h4>
                        <input  :value="dataEdit.MOBILE" type="text" class="form-control" name="MOBILEE" ref="MOBILEE" placeholder="Mobile"  disabled maxlength="10">
                    </div><br><br>
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">อีเมล</h4>
                        <input v-model="dataEdit.EMAIL" type="email" class="form-control" name="EMAILE" ref="EMAILE" maxlength="100" placeholder="E-mail" >
                    </div>
                    <div class="col-12 col-md-6">
                        <h4 style="margin: 0px; font-size: 100%;">หมายเลขสมาชิก The 1</h4><span style="color: red;" v-if="NT1C"> (ไม่พบเลขThe1)</span>
                        <input v-model="dataEdit.T1C" type="text" class="form-control" name="T1CE" ref="T1CE" placeholder="TheOneCard" maxlength="16">
                        <a :class="`font-color-${qtBu} pointer`" style="font-size: 1em; text-decoration: underline;" @click="getTheOneNumber()" target="_blank">เช็คหมายเลข The 1</a>
                    </div>
                </div>

            </div>

            
        </div>
    </div>
</template>


<script>
  import * as serviceAPI from '../services/API.service';
  import * as serviceMain from '../services/main.service';

  export default {
    name: 'EditUserInfoForm',
    data: function () {
      return {
        editForm:false,
        dataSelected:{
          CNAME:'',
          MOBILE:'',
          EMAIL:'',
          T1C:'',
          VIPID:'',
          SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
        },
        dataEdit:{
          CNAME:'',
          MOBILE:'',
          EMAIL:'',
          T1C:'',
          VIPID:'',
          SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
        },
        NT1C:false
      }
    },
    props: [
      "dataEditProps",
      "mode"
    ],
    mounted() {
      this.startProps()
    },
    methods: {
      async startProps(){
        try {
          this.dataSelected = this.dataEditProps;
          this.dataEdit = this.dataEditProps;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async startEdit(){
        try {
          this.editForm = true;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async confirmEdit(){
        try {
          let data = this.dataEdit;
          await serviceAPI.call_API('put', 'user/Custinfo', data,'auth');
          serviceMain.showSuccessAlertWithTime(this, "แก้ไข User สำเร็จ",1500)
          this.$emit('confirmEdit',this.dataEdit)
          this.editForm = false;
          this.$store.dispatch('service/setReloadOrderInfo')
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }

      },
      async getTheOneNumber(){
        try {
          if(this.dataEdit.MOBILE.length != 10){
            return
          }
          let data = this.dataEdit;
          let getAPI = await serviceAPI.call_API('post', 'user/findTheOne', data,'auth');
          let getData = getAPI.data.dbitems[0];
          
          if(!getData ||getData.T1CNumber=='NOOK'){
            this.dataEdit.T1C = ''
            // this.dataEdit.CNAME = ''
            this.NT1C = true
            return
            // throw new Error('ไม่พบเลขเดอะวัน')
          }
          this.$swal.fire({
            html:
              `<h2>T1C : ${getData.T1CNumber}</h2><br>
              <h2>ชื่อผู้ใช้ : ${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}</h2>`,
            showCancelButton: false,
            showDenyButton: true,
            confirmButtonColor: this.getBuColor,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: "ตกลง",
            denyButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.value) {
              this.dataEdit.T1C = getData.T1CNumber;
              this.dataEdit.CNAME = `${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}`;
            }
          });
          
          this.NT1C = false
        } catch (error) {
          this.dataEdit.T1C = ''
          this.dataEdit.CNAME = ''
          this.NT1C = true
          // serviceMain.showErrorAlert(this, error.message)
        }
      },

    },
    computed: {
        qtBu: function () {
            return this.$store.getters['customer/getQTBU'];
        }
    },
  watch:{
    dataEditProps:function(){
      this.startProps()
    }
  }
}
</script>

<style scoped lang="scss">
$color-PWB: #55246c;
$color-bnb: #006e63;
$color-at1: #3866af;

.cardWhite{
  margin: 0px;
  background-color: white;
  padding:20px 20px;
  border-radius: 7px;
  height: auto;
  margin-bottom: 0px;
  min-height: auto;
}
  .selectMail{
    border-radius: 15px !important;
    height: 35px; 
    padding:0px 15px; 
    font-family: 'kittithadabold75regular'; 
    color:black; 
    background-color: rgb(241, 241, 241);
    border-radius: 25px; 
  }
  .form-control {
    height: 30px;
    padding: 0px 15px;
      width: 100% !important;
      border-radius: 4px;
      border: .5px solid #ccc;
      display: inline-block !important;
  }

  .col-regis {
      padding: 0px 20px !important;
  }
  .checkmark {
    top: 4px !important;
    height: 20px !important;
    width: 20px !important;
  }

  .checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  @mixin btn-sm($color) {
    background-color: $color;
    border: none;
    color: white;
    padding: 2px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 5px 0px;
    cursor: pointer;
    border-radius: 4px; 
  }
  .btn-sm-PWB{
    @include btn-sm($color-PWB)
  }
  .btn-sm-bnb{
    @include btn-sm($color-bnb)
  }
  .btn-sm-at1{
    @include btn-sm($color-at1)
  }
</style>