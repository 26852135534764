<template>
      <div class="row">
      <div class="col-12 card-white">
        <!-- <vue-barcode  value="Hello, World!" :options="{ displayValue: false }"></vue-barcode> -->
        <table id="example" class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Cart Ref</th>
                    <th>Store</th>
                    <th>Cust id</th>
                    <th>Customer Name</th>
                    <th>Order Date</th>
                    <th>Last Update</th>
                    <th>NItem</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Status Name</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr  v-for="(e,index) in listData" @click="selectUser(e)" :key="index">
                    <td>{{e.REF}}</td>
                    <td>{{e.store}}</td>
                    <td>{{e.custid}}</td>
                    <td>{{e.custname}}</td>
                    <td>{{e.orderdate}}</td>
                    <td>{{e.LUPD}}</td>
                    <td>{{e.nitem}}</td>
                    <td>{{e.amount}}</td>
                    <td>{{e.status}}</td>
                    <td>{{e.STNAME}}</td>
                </tr>
                
            </tbody>
            <tfoot>
                <tr>
                    <th>Cart Ref</th>
                    <th>Store</th>
                    <th>Cust id</th>
                    <th>Customer Name</th>
                    <th>Order Date</th>
                    <th>Last Update</th>
                    <th>NItem</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Status Name</th>
                </tr>
            </tfoot>
        </table>
      </div>
    </div>
</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
  export default {
    name: 'UserOrderHistory',
    components: {
    
    },
    data: function () {
      return {
        listData:[],
        customTable: {
          "order": [[ 5, "desc" ]], 
          scrollX: true,
          "columnDefs": [
            {
              "targets": [0,1,2,6,7,8,9],
              "width": '100px'
            },
            {
              "targets": [3,4,5],
              "width": '150px'
            }
          ]
        }
      }
    },
    mounted() {
      this.getDatatable()
    },
    props: [
      
    ],
    methods: {
      async setDataTable(){
        $('#example').DataTable(this.customTable);
      },
      async getDatatable(){
        try {
            let data={
              USERID:this.$store.getters['auth/getQTUSERID'] , 
              SALESOURCE:this.$store.getters['auth/getQTSALESOURCE']
            }
            let getAPI = await serviceAPI.call_API('post',`user/userOrderHistory`,data,'auth');
            this.listData = getAPI.data.dbitems;
            
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      async selectUser(val){
        try {
            if(val.status && val.status.trim()!=""){
              throw new Error("Order นี้ทำรายการเสร็จสมบูรณ์ไปแล้ว")
            }
            this.$store.dispatch('customer/setQTCUSTINFO', {
                CNAME: val.custname,
                MOBILE: val.MOBILE,
                EMAIL: val.EMAIL,
                T1C: val.T1C,
                VIPID: val.custid,
                SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
            })
            this.$store.dispatch('customer/setQTCUSTREF', val.REF)
            this.$store.dispatch('customer/setQTCOPYREF', "")
            this.$store.dispatch('customer/setQTCREDITBAL', "")
            this.$store.dispatch('customer/setQTSHOPITEM', val.nitem)
     
            let getCURSTC = val.stcode
            this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
            let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                "STCODE": getCURSTC
            }, 'auth');
            let getBU = getAPI.data.dbitems[0].BU;
            this.$store.dispatch('customer/setQTBU', getBU)

            let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
            let locationsTEMP = getQTSAVESTORE.split('|');
            let locations = locationsTEMP.map(e => e.split(','));
            let getQTStoreNow = getCURSTC
            let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
            let getStoreName = QTStoreName[0][1];
            this.$store.dispatch('service/setQTSTORENAME', getStoreName)
            this.$router.push({ name: 'Cart'})
            // EMAIL:"mrUL00000027@dummy.com"
            // LUPD:"2021-09-21 01:21:03 "
            // MEMID:3010
            // MOBILE:"0830357083"
            // REF:"QT2109000238"
            // STNAME:"ยืนยันใบเสนอราคา"
            // amount:"3,160.00"
            // custid:"UL00000027"
            // custname:"K.ThaiWat UL00000027 (0830357083)"
            // nitem:2
            // orderdate:"2021-09-21 01:20:46 "
            // status:"C"
            // stcode:"920"
            // store:"บางนา(920)"
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      }
    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      }
    },
    watch: {
      listData: function() {
        this.$nextTick(() => {
          this.setDataTable();
        })
      }
    }
  }
       
</script>

<style scoped>
.card-white{
  background-color: white;
  border-radius: 8px;
  margin: 15px 0px;
  padding: 15px;
}
</style>